import React from "react";
import Blockies from "@pacta-app/react-blockies";
//import { getProfile } from "3box";
import PropTypes from "prop-types";
import Copy from "../components/Copy";

export default class Address extends React.Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
    nocopy: PropTypes.bool,
  };

  state = {
    profile: null,
    address: null,
    copied: false,
  };

  componentDidMount = async () => {
    this._ismounted = true;
    var profile = null; //await getProfile(this.props.address);
    if (this._ismounted)
      // prevent error when componen was already unmounted
      this.setState({ address: this.props.address, profile });
  };

  componentWillUnmount() {
    this._ismounted = false;
  }

  icon = () => (
    /* this.state.profile && this.state.profile.image ? (
      <img
        src={
          "https://ipfs.infura.io/ipfs/" +
          this.state.profile.image[0].contentUrl["/"]
        }
        alt={this.props.address}
      />
    ) : ( */
    <Blockies
      seed={this.props.address.toLowerCase()}
      size={10}
      scale={4}
      className='blockies'
    />
  );
  //);

  address = () => <div className='text'>{this.props.address}</div>;

  render = () => (
    <div className='address'>
      <div className='icon'>{this.icon()}</div>
      <div className='top'>
        {this.state.profile && this.state.profile.name
          ? this.state.profile.name
          : this.address()}
      </div>
      <div className='bottom'>
        {this.state.profile && this.state.profile.name
          ? this.address()
          : "Ethereum Account"}
      </div>
      {this.props.nocopy ? <></> : <Copy text={this.props.address} />}
    </div>
  );
}
