import React from "react";
//import PropTypes from "prop-types";
import Accordeon from "./Accordeon";

export default class Tabs extends Accordeon {
  /* static propTypes = {
    active: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  state = {
    active: 0,
  };
  componentDidMount() {
    var active =
      this.props.active !== null ? this.props.active : this.state.active;
    while (
      active < this.props.children.length &&
      this.props.children[active].props.hide
    )
      ++active;
    if (active == this.props.children.length) active = 0;
    if (active !== this.state.active) this.setState({ active });
  } */
  render = () => (
    <div className="tabs">
      <div className="tabbar">
        {this.props.children.map((v, i) =>
          v.props.hide ? null : (
            <button
              disabled={i === this.state.active}
              key={i}
              onClick={() => this.setState({ active: i })}
            >
              {v.props.name}
            </button>
          )
        )}
      </div>
      <div className="tabname">
        {this.props.children.map((v, i) =>
          i === this.state.active ? v.props.name : null
        )}
      </div>
      <div className="content">
        {this.props.children.find(
          (v, i) => i === this.state.active && !v.props.hide
        )}
        <div className="clear" />
      </div>
    </div>
  );
}
