import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "./ButtonBar";
import { withTranslation } from "react-i18next";

class Form extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    heading: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    ok: PropTypes.func,
    cancel: PropTypes.func,
    okText: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]),
    cancelText: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]),
    buttons: PropTypes.object,
    ready: PropTypes.bool,
    nomargin: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.keyFunction = this.keyFunction.bind(this);
  }

  keyFunction(event) {
    if (this.props.show !== false) {
      let key = event.which || event.keyCode;
      if (key === 27) {
        if (this.props.cancel) this.props.cancel();
      } else if (this.props.ready !== false && key === 13) {
        if (this.props.ok) this.props.ok();
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyFunction, false);
  }

  form = () => (
    <form
      className={
        "form" +
        (this.props.heading ? " frame" : "") +
        (this.props.nomargin ? " nomargin" : "")
      }
      action=''
    >
      {this.content()}
    </form>
  );

  div = () => (
    <div
      className={
        "form" +
        (this.props.heading ? " frame" : "") +
        (this.props.nomargin ? " nomargin" : "")
      }
    >
      {this.content()}
    </div>
  );

  content = () => (
    <>
      {this.props.heading ? (
        <div className='heading'>
          <h2>{this.props.heading}</h2>
        </div>
      ) : (
        <></>
      )}
      <div className='content'>
        {this.props.children}
        {this.props.ok || this.props.cancel ? (
          <ButtonBar>
            {this.props.ok ? (
              <input
                type='submit'
                disabled={this.props.ready === false}
                onClick={(e) => {
                  this.props.ok();
                  e.preventDefault();
                }}
                value={this.props.okText ?? this.props.t("form.ok")}
              />
            ) : (
              <></>
            )}
            {this.props.cancel ? (
              <input
                type='button'
                className='secondary'
                onClick={this.props.cancel}
                value={
                  this.props.cancelText
                    ? this.props.cancelText
                    : this.props.t("form.cancel")
                }
              />
            ) : (
              <></>
            )}
            {this.props.buttons}
          </ButtonBar>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  render = () =>
    typeof this.props.show === "undefined" ? (
      this.form()
    ) : (
      <div className={"modal" + (this.props.show ? " show" : "")}>
        {this.div()}
      </div>
    );
}

export default withTranslation()(Form);
