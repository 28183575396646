import React from "react";
import PropTypes from "prop-types";
import QRCodeImg from "@pacta-app/qrcode-react";
import Copy from "./Copy";
import { withTranslation } from "react-i18next";

class QRCode extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    copy: PropTypes.bool,
  };

  code = () => (
    <>
      <QRCodeImg size={800} value={this.props.value} />
      <p
        className={
          this.props.value.length === 42
            ? "flick"
            : this.props.value.length === 66
            ? "flack"
            : ""
        }
      >
        {this.props.value}
      </p>
    </>
  );

  render = () => (
    <div className='qrcode'>
      {this.props.copy ? (
        <Copy text={this.props.value}>{this.code()}</Copy>
      ) : (
        this.code()
      )}
    </div>
  );
}

export default withTranslation()(QRCode);
