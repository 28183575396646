import React from "react";
import lib from "../lib";
import EthereumContext from "../context/EthereumContext";

export default class ProviderChoice extends React.Component {
  render = () => (
    <EthereumContext.Consumer>
      {({ provider, setProvider }) => (
        <>
          <select
            defaultValue={provider ? provider.name : "unknown"}
            onChange={(e) => setProvider(e.target.value)}
          >
            {lib.providers.map((v, i) =>
              v.valid ? (
                <option
                  key={i}
                  className={
                    provider && v.name === provider.name ? "active" : null
                  }
                  value={v.name}
                >
                  {v.name}
                </option>
              ) : null
            )}
          </select>
        </>
      )}
    </EthereumContext.Consumer>
  );
}
