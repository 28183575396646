import React from "react";
import PropTypes from "prop-types";

export default class Progress extends React.PureComponent {
  static propTypes = {
    operation: PropTypes.string,
    progress: PropTypes.number,
  };
  render = () => (
    <div className='progress'>
      <div className='text'>
        {(this.props.operation ? this.props.operation + " " : "") +
          (this.props.progress !== null
            ? Number(this.props.progress).toFixed(0) + "%"
            : "")}
      </div>
      <div
        className={this.props.progress !== null ? "bar" : "bar undefined"}
        style={
          this.props.progress !== null
            ? { width: this.props.progress + "%" }
            : { width: "100%" }
        }
      ></div>
    </div>
  );
}
