window.onload = () =>
  [].slice
    .call(document.getElementsByClassName("infoemail"))
    .forEach((element) => element.setAttribute("href", "mailto:info@pacta.ag"));

// fix view height for weird mobile browsers
// use it as: height: calc(var(--vh, 1vh) * 100);
function fixheight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}
window.onresize = fixheight;
fixheight();
