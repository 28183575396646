import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";
import en from "./translations/en.yml";
import de from "./translations/de.yml";
import renderHTML from "react-render-html";
import ReactMarkdown from "react-markdown";
import { utils } from "ethers";

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .use({
    type: "postProcessor",
    name: "formatted-text",
    process: (value, key, options, translator) => {
      if (value.match(/^ *html: */))
        return renderHTML(value.replace(/^ *html: */, ""));
      if (value.match(/^ *markdown: */))
        return (
          <ReactMarkdown
            source={value.replace(/^ *markdown: */, "")}
            renderers={{
              link: (props) => (
                <a href={props.href} target='_blank' rel='noreferrer'>
                  {props.children}
                </a>
              ),
            }}
          />
        );
      return value;
    },
  })
  .init({
    postProcess: "formatted-text",
    fallbackLng: "en",
    returnEmptyString: false,
    debug: true, // have a common namespace used around the full app
    /* returnedObjectHandler: (key, value, options) => {
      if ("html" in value) return renderHTML(value.html);
      if ("markdown" in value) return <ReactMarkdown source={value.markdown} />;
      return value;
    }, */
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
      format: (value, formatting, lang) => {
        switch (formatting) {
          case "amount":
            return utils.commify(value);
          default:
            return value;
        }
      },
    },
    react: {
      wait: true,
    },
  });

i18n.addResourceBundle("en", "translations", en);
i18n.addResourceBundle("de", "translations", de);

export default i18n;
