import React from "react";
import { withTranslation } from "react-i18next";
import Grid from "./components/Grid";
import Container from "./components/Container";
import Card from "./components/Card";
import StepsToCoin from "./StepsToCoin";
import ChartImage from "./style/images/20200730-ethereum-chart.png";
import PropTypes from "prop-types";
import Enjoy from "./style/images/enjoy.jpg";
import { mdiFrequentlyAskedQuestions } from "@mdi/js";

class LandingPage extends React.Component {
  static propTypes = {
    current: PropTypes.number,
  };
  render = () => (
    <>
      <StepsToCoin current={this.props.current} />
      <Container>
        <h2>
          {this.props.t("landingpage.titlewhy")}
          <span className='subtitle'>
            {this.props.t("landingpage.subtitlewhy")}
          </span>
        </h2>
        <Grid size='4'>
          <Card icon={mdiFrequentlyAskedQuestions}>
            {this.props.t("landingpage.wheretouse")}
            <p className='bottom'>
              <button disabled>{this.props.t("landingpage.more")}</button>
            </p>
          </Card>
          <Card icon={mdiFrequentlyAskedQuestions}>
            {this.props.t("landingpage.investment")}
            <p>
              <img src={ChartImage} alt='Ethereum chart of one year' />
            </p>
            <p className='bottom'>
              <button disabled>{this.props.t("landingpage.more")}</button>
            </p>
          </Card>
          <Card icon={mdiFrequentlyAskedQuestions}>
            {this.props.t("landingpage.privacy")}
            <p className='bottom'>
              <button disabled>{this.props.t("landingpage.more")}</button>
            </p>
          </Card>
          <Card icon={mdiFrequentlyAskedQuestions}>
            {this.props.t("landingpage.independence")}
            <p className='bottom'>
              <button disabled>{this.props.t("landingpage.more")}</button>
            </p>
          </Card>
        </Grid>
      </Container>
      <div className='to-inverse'></div>
      <Container inverse>
        {this.props.t("landingpage.titletrust")}
        <div className='grid6'>
          <div className='card'>
            <div className='icon'>
              <svg width='24' height='24' viewBox='0 0 24 24'>
                <path d='M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9' />
              </svg>
            </div>
            <div className='content'>
              {this.props.t("landingpage.encryption")}
            </div>
          </div>
          <div className='card inverse'>
            <div className='icon'>
              <svg width='24' height='24' viewBox='0 0 24 24'>
                <path d='M11 6H14L17.29 2.7A1 1 0 0 1 18.71 2.7L21.29 5.29A1 1 0 0 1 21.29 6.7L19 9H11V11A1 1 0 0 1 10 12A1 1 0 0 1 9 11V8A2 2 0 0 1 11 6M5 11V15L2.71 17.29A1 1 0 0 0 2.71 18.7L5.29 21.29A1 1 0 0 0 6.71 21.29L11 17H15A1 1 0 0 0 16 16V15H17A1 1 0 0 0 18 14V13H19A1 1 0 0 0 20 12V11H13V12A2 2 0 0 1 11 14H9A2 2 0 0 1 7 12V9Z' />
              </svg>
            </div>
            <div className='content'>{this.props.t("landingpage.trust")}</div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='24' height='24' viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  d='M21,18V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V6H12C10.89,6 10,6.9 10,8V16A2,2 0 0,0 12,18M12,16H22V8H12M16,13.5A1.5,1.5 0 0,1 14.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,12A1.5,1.5 0 0,1 16,13.5Z'
                />
              </svg>
            </div>
            <div className='content'>{this.props.t("landingpage.keys")}</div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='24' height='24' viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  d='M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M11,18H13V15.87C14.73,15.43 16,13.86 16,12A4,4 0 0,0 12,8A4,4 0 0,0 8,12C8,13.86 9.27,15.43 11,15.87V18Z'
                />
              </svg>
            </div>
            <div className='content'>
              {this.props.t("landingpage.trustprivacy")}
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='24' height='24' viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  d='M23 16V15.5A2.5 2.5 0 0 0 18 15.5V16A1 1 0 0 0 17 17V21A1 1 0 0 0 18 22H23A1 1 0 0 0 24 21V17A1 1 0 0 0 23 16M22 16H19V15.5A1.5 1.5 0 0 1 22 15.5M7 8.9H11V7H7A5 5 0 0 0 7 17H11V15.1H7A3.1 3.1 0 0 1 7 8.9M8 11V13H16V11M13 15.1V17H15V15.1M17 7H13V8.9H17A3.09 3.09 0 0 1 19.94 11A5.12 5.12 0 0 1 20.5 11H21.9A5 5 0 0 0 17 7Z'
                />
              </svg>
            </div>
            <div className='content'>
              {this.props.t("landingpage.blockchain")}
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='24' height='24' viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  d='M15.41,22C15.35,22 15.28,22 15.22,22C15.1,21.95 15,21.85 14.96,21.73L12.74,15.93C12.65,15.69 12.77,15.42 13,15.32C13.71,15.06 14.28,14.5 14.58,13.83C15.22,12.4 14.58,10.73 13.15,10.09C11.72,9.45 10.05,10.09 9.41,11.5C9.11,12.21 9.09,13 9.36,13.69C9.66,14.43 10.25,15 11,15.28C11.24,15.37 11.37,15.64 11.28,15.89L9,21.69C8.96,21.81 8.87,21.91 8.75,21.96C8.63,22 8.5,22 8.39,21.96C3.24,19.97 0.67,14.18 2.66,9.03C4.65,3.88 10.44,1.31 15.59,3.3C18.06,4.26 20.05,6.15 21.13,8.57C22.22,11 22.29,13.75 21.33,16.22C20.32,18.88 18.23,21 15.58,22C15.5,22 15.47,22 15.41,22M12,3.59C7.03,3.46 2.9,7.39 2.77,12.36C2.68,16.08 4.88,19.47 8.32,20.9L10.21,16C8.38,15 7.69,12.72 8.68,10.89C9.67,9.06 11.96,8.38 13.79,9.36C15.62,10.35 16.31,12.64 15.32,14.47C14.97,15.12 14.44,15.65 13.79,16L15.68,20.93C17.86,19.95 19.57,18.16 20.44,15.93C22.28,11.31 20.04,6.08 15.42,4.23C14.33,3.8 13.17,3.58 12,3.59Z'
                />
              </svg>
            </div>
            <div className='content'>
              {this.props.t("landingpage.standards")}
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <img style={{ maxWidth: "80rem" }} src={Enjoy} alt='enjoy' />
        </div>
      </Container>
    </>
  );
}

export default withTranslation()(LandingPage);
