import React from "react";
import EthereumContext from "./context/EthereumContext";
import Select from "./components/Select";
import Option from "./components/Option";
import ProviderChoice from "./basic/ProviderChoice";
import SwaggerClient from "swagger-client";
import bityAPI from "./bity/exchange_api_v2.openapi.yaml";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import Form from "./components/Form";
import ButtonBar from "./components/ButtonBar";
import CheckBox from "./components/CheckBox";
import HelpButton from "./components/HelpButton";
//import Button from "./components/Button";
import Range from "./components/Range";

class Settings extends React.Component {
  static propTypes = {};
  state = { purge: false, purgeOk: false, bity: null, currencies: [] };
  componentDidMount = () => {
    new SwaggerClient(bityAPI).then(
      (bity) => {
        SwaggerClient.http.withCredentials = true;
        this.setState({ bity });
        bity.apis.Currencies.get_currencies({ tags: ["fiat"] }).then(
          (res) => this.setState({ currencies: res.obj.currencies }),
          (error) =>
            console.error("failed to load currencies from Bity API", error) // ignore
        );
      },
      (error) => console.error("failed to Load Bity API", error)
    );
  };
  purge = () => (
    <Form
      ready={this.state.purgeOk && !this.props.nopurge}
      show={this.state.purge && !this.props.nopurge}
      cancel={() => this.setState({ purgeOk: false, purge: false })}
      cancelText={this.props.t("purge.cancel")}
      ok={() => {
        this.setState({ purgeOk: false, purge: false });
        this.props.purge();
      }}
      okText={this.props.t("purge.ok")}
    >
      {this.props.t("purge.warning")}
      <p>
        <CheckBox
          name='purge'
          checked={this.state.purgeOk}
          changed={(e) => this.setState({ purgeOk: e.target.checked })}
        >
          {this.props.t("purge.agree")}
        </CheckBox>
      </p>
    </Form>
  );
  render = () => (
    <EthereumContext.Consumer>
      {({ options, setOption }) => (
        <>
          {this.purge()}
          <h1>{this.props.t("settings.title")}</h1>

          <label>{this.props.t("settings.currency")}</label>
          <select
            onChange={(e) => setOption("currency", e.target.value)}
            value={options.currency}
            name='option'
          >
            {this.state.currencies.map((v, i) => (
              <option key={i} value={v.code}>
                {v.code}
              </option>
            ))}
          </select>

          <label>{this.props.t("settings.language") + " "}</label>
          <select
            defaultValue={i18next.language}
            onChange={(e) => setOption("language", e.target.value)}
          >
            {["en", "de"].map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>

          {options.customerType < 10 ? (
            <></>
          ) : (
            <>
              <h2>{this.props.t("settings.experts")}</h2>
              <label for='security'>
                {this.props.t("settings.security")}
                <HelpButton>{this.props.t("settings.securityhelp")}</HelpButton>
              </label>
              <Range
                min='10'
                max='30'
                value={options.security}
                onChange={(e) => setOption("security", e.target.value)}
                className={
                  options.security < 13
                    ? "bad"
                    : options.security < 17
                    ? "medium"
                    : "good"
                }
              />
              {/* <ButtonBar>
                <Button>{this.props.t("settings.encrypt")}</Button>
              </ButtonBar> */}
            </>
          )}

          {options.customerType < 20 ? (
            <></>
          ) : (
            <>
              <h2>{this.props.t("settings.developer")}</h2>
              <label>{this.props.t("settings.network")}</label>
              <ProviderChoice />
            </>
          )}

          <h2>{this.props.t("settings.danger")}</h2>

          <ButtonBar>
            <button
              onClick={() => this.setState({ purge: true })}
              className='secondary'
              disabled={this.props.nopurge}
            >
              {this.props.t("settings.purge")}
            </button>
          </ButtonBar>

          {this.props.t("settings.purgehelp")}

          <h2>{this.props.t("settings.userlevel")}</h2>
          <Select
            name='customer-type'
            selected={options.customerType}
            select={(customerType) => setOption("customerType", customerType)}
          >
            <Option heading={this.props.t("settings.ubeginner")} value={0} />
            <Option heading={this.props.t("settings.uexpert")} value={10} />
            <Option heading={this.props.t("settings.udeveloper")} value={20} />
          </Select>
        </>
      )}
    </EthereumContext.Consumer>
  );
}

export default withTranslation()(Settings);
