import React from "react";
import PropTypes from "prop-types";
import lib from "./lib";

export default class Error extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    error: PropTypes.object,
  };
  state = { details: false };
  render = () => (
    <section
      className='alert-box error'
      onClick={() => this.setState({ details: !this.state.details })}
    >
      <h2>{this.props.heading ?? this.props.error?.name}</h2>

      <div className='message'>
        {typeof this.props.message === "string" ? (
          <p>{this.props.message}</p>
        ) : (
          this.props.message ??
          this.props.error?.message ??
          this.props.error?.toString()
        )}
        {this.state.details && this.props.error ? (
          <dl>
            {lib
              .getAllPropertyNames()(this.props.error)
              .filter(
                (k) =>
                  k !== "__proto__" && typeof this.props.error[k] !== "function"
              )
              .map((k, i) => (
                <div key={i}>
                  <dt key={"K" + i}>{k}</dt>
                  <dd key={"V" + i}>
                    {this.props.error?.[k] &&
                    typeof this.props.error[k] === "object" ? (
                      <pre>
                        {JSON.stringify(
                          this.props.error?.[k] ?? "",
                          lib.flatten(),
                          4
                        )}
                      </pre>
                    ) : (
                      this.props.error?.[k]?.toString()
                    )}
                  </dd>
                </div>
              ))}
          </dl>
        ) : null}
      </div>
    </section>
  );
}
