import React from "react";
import PropTypes from "prop-types";
import { mdiHelpCircle } from "@mdi/js";
import Button from "./Button";
import Close from "./Close";

export default class HelpButton extends React.PureComponent {
  state = { show: false };
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  };
  render = () => (
    <div className='help'>
      <Button
        onClick={() => this.setState({ show: !this.state.show })}
        icon={mdiHelpCircle}
      />
      {this.state.show && (
        <div className='content' onClick={() => this.setState({ show: false })}>
          <div className='text'>
            <Close close={() => this.setState({ show: false })}>
              {this.props.children}
            </Close>
          </div>
        </div>
      )}
    </div>
  );
}
