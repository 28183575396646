import React from 'react';
import PropTypes from 'prop-types';

export default class Select extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.any,
    select: PropTypes.func,
    children: PropTypes.array.isRequired
  };
  state = {
    selected: null
  };
  componentDidMount = () =>
    this.setState({
      selected: this.props.selected ?? this.props.children?.[0]?.props?.value
    });
  render = () => (
    <div className="select">
      {this.props.children.map((child, i) =>
        React.cloneElement(child, {
          key: i,
          name: this.props.name,
          selected: child.props.value === this.state.selected,
          select: () => {
            this.setState({ selected: child.props.value });
            if (this.props.select) this.props.select(child.props.value);
          }
        })
      )}
    </div>
  );
}
