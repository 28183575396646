import React from 'react';
import PropTypes from 'prop-types';
import ButtonBar from './ButtonBar';
import { withTranslation } from 'react-i18next';

class TLDR extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    abstract: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.object
    ]).isRequired
  };
  state = { open: false };
  render = () => (
    <section className="tldr">
      <div className="intro">
        {this.props.abstract}
        <ButtonBar>
          {this.state.open ? (
            <button onClick={() => this.setState({ open: false })}>
              {this.props.t('tldr.close')}
            </button>
          ) : (
            <button onClick={() => this.setState({ open: true })}>
              {this.props.t('tldr.open')}
            </button>
          )}
        </ButtonBar>
      </div>
      {this.state.open ? this.props.children : null}
    </section>
  );
}

export default withTranslation()(TLDR);
