const getAllPropertyNames = () => {
  const seen = new WeakSet();
  return (obj) => {
    let props = [];
    if (typeof obj !== "object") return props;
    do {
      if (seen.has(obj)) return [];
      seen.add(obj);
      Object.getOwnPropertyNames(obj).forEach((prop) => {
        if (props.indexOf(prop) === -1) props.push(prop);
      });
    } while ((obj = Object.getPrototypeOf(obj)));
    return props;
  };
};

const flatten = () => {
  const seen = new WeakSet();
  const getPropertyNames = getAllPropertyNames();
  return (key, value) => {
    if (value !== null && typeof value === "object") {
      if (seen.has(value)) return;
      seen.add(value);
      let result = {};
      getPropertyNames(value).forEach((k) => (result[k] = value[k]));
      return result;
    }
    return value;
  };
};

/** parses buff as JSON
 *  @param {string} buff - string to parse
 *  @param {boolean} optional - traces a warning if decoding fails, unless optional is true
 *  @returns {Object} the decoded object
 *  @returns {undefined} on error */
const parse = (buff, optional = false) => {
  try {
    return JSON.parse(buff);
  } catch (e) {
    if (!optional)
      console.warn(
        `Not a JSON buffer: ${e.message}`,
        buff,
        e,
        e.stack ?? "no stack trace available"
      );
    return undefined;
  }
};

const lib = { getAllPropertyNames, flatten, parse };
export default lib;
