import React from 'react';
import PropTypes from 'prop-types';
export default class Warning extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired
  };
  render = () => (
    <section className="alert-box warning">
      <h2>{this.props.heading}</h2>
      <div className="message">{this.props.message}</div>
    </section>
  );
}
