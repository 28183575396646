import React from 'react';
import PropTypes from 'prop-types';

export default class CheckBox extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    changed: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ]).isRequired
  };
  render = () => (
    <label className="check" htmlFor={this.props.name}>
      {this.props.children}
      <input
        onChange={this.props.changed}
        id={this.props.name}
        name={this.props.name}
        type="checkbox"
        checked={this.props.checked}
      />
      <span />
    </label>
  );
}
