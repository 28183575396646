import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';

export default class Button extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ])
  };

  render = () => (
    <button type="button" {...this.props}>
      {this.props.icon ? (
        <>
          <div className="icon">
            <Icon path={this.props.icon} />
          </div>
          {this.props.children ? (
            <div className="content">{this.props.children}</div>
          ) : null}
        </>
      ) : (
        this.props.children
      )}
    </button>
  );
}
