import React from "react";
import PropTypes from "prop-types";

export default class Accordeon extends React.PureComponent {
  static propTypes = {
    active: PropTypes.number,
    children: PropTypes.array,
  };
  state = {
    active: 0,
  };
  componentDidMount = () => {
    this.calculateActive(this.props.active ?? this.state.active);
  };
  componentDidUpdate = () => this.calculateActive(this.state.active);
  calculateActive = (active) => {
    while (
      active < this.props.children.length &&
      this.props.children[active].props.hide
    )
      ++active;
    if (active === this.props.children.length) {
      active = 0; // restart
      while (
        active < this.props.children.length &&
        this.props.children[active].props.hide
      )
        ++active;
      if (active === this.props.children.length) active = 0;
    }
    if (active !== this.state.active) this.setState({ active });
  };
  render = () => (
    <div className='tabs'>
      <div className='tabname'>
        {this.props.children[this.state.active].props.name}
      </div>
      <div className='content'>{this.props.children[this.state.active]}</div>
      <div className='tabbar'>
        {this.props.children
          .filter((v) => !v.props.hide)
          .map((v, i) => (
            <button
              disabled={i === this.state.active}
              key={i + v.props.name}
              onClick={() => this.setState({ active: i })}
            >
              {v.props.name}
            </button>
          ))}
      </div>
    </div>
  );
}
