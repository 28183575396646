import React from "react";
import PropTypes from "prop-types";

export default class Note extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  };
  render = () => <p className='note'>{this.props.children}</p>;
}
