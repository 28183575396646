import React from "react";
import PropTypes from "prop-types";

export default class Grid extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  };
  render = () => (
    <div
      className={
        "grid" +
        this.props.size +
        (this.props.type ? " " + this.props.type : "")
      }
    >
      {this.props.children}
    </div>
  );
}
