const getAllPropertyNames = () => {
  const seen = new WeakSet();
  return (obj) => {
    let props = [];
    if (typeof obj !== "object") return props;
    do {
      if (seen.has(obj)) return [];
      seen.add(obj);
      Object.getOwnPropertyNames(obj).forEach((prop) => {
        if (props.indexOf(prop) === -1) props.push(prop);
      });
    } while ((obj = Object.getPrototypeOf(obj)));
    return props;
  };
};
const flatten = () => {
  const seen = new WeakSet();
  const getPropertyNames = getAllPropertyNames();
  return (key, value) => {
    if (value !== null && typeof value === "object") {
      if (seen.has(value)) return;
      seen.add(value);
      let result = {};
      getPropertyNames(value).forEach((k) => (result[k] = value[k]));
      return result;
    }
    return value;
  };
};

const lib = { getAllPropertyNames, flatten };
export default lib;
