import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';

export default class Icon extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.object
    ])
  };

  render = () => (
    <div className="iconbox">
      <div className="boxicon">
        <MdiIcon path={this.props.icon} />
      </div>
      {this.props.children ? (
        <div className="content">{this.props.children}</div>
      ) : (
        <></>
      )}
    </div>
  );
}
