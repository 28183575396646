import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { mdiClipboardPlusOutline, mdiClipboardCheckOutline } from '@mdi/js';
import Icon from './Icon';
import { withTranslation } from 'react-i18next';

class Copy extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.object
    ])
  };

  state = {
    copied: false
  };

  copied = () => {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000);
  };

  render = () => {
    return (
      <div className="copy">
        <CopyToClipboard text={this.props.text ?? this.props.children}>
          <div onClick={this.copied} className="copyicon">
            {this.state.copied ? (
              <Icon icon={mdiClipboardCheckOutline}>
                {this.props.t('copy.copied')}
              </Icon>
            ) : (
              <Icon
                title={this.props.t('copy.clipboard')}
                icon={mdiClipboardPlusOutline}
              >
                <div>{this.props.t('copy.copy')}</div>
              </Icon>
            )}
            {this.props.children && (
              <div className="message">{this.props.children}</div>
            )}
          </div>
        </CopyToClipboard>
      </div>
    );
  };
}

export default withTranslation()(Copy);
