import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class Range extends React.Component {
  static propTypes = {
    from: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { percent: this.percent(props.value) };
  }

  percent = (v) =>
    (100 * (v - (this.props.min ?? 0))) /
    ((this.props.max ?? 100) - (this.props.min ?? 0));

  adjust = (v) => this.setState({ percent: this.percent(v) });

  render = () => (
    <div className={"range " + (this.props.className ?? "")}>
      <div>{this.props.from}</div>
      <div className='input'>
        <input
          type='range'
          min={this.props.min ?? 0}
          max={this.props.max ?? 100}
          value={this.props.value}
          onChange={(e) => {
            this.adjust(e.target.value);
            this.props.onChange(e);
          }}
        />
        <div
          className='value'
          style={{
            left: `calc(${this.state.percent}% - ${this.state.percent} / 100 * 1em`,
          }}
        >
          {this.props.value}
        </div>
      </div>
      <div>{this.props.to}</div>
    </div>
  );
}

export default withTranslation()(Range);
