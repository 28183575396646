import React from 'react';
import PropTypes from 'prop-types';
import ButtonBar from './ButtonBar';
import { withTranslation } from 'react-i18next';

class Unveal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    text: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.object
    ]).isRequired
  };
  state = { open: false, timeout: null };
  componentWillUnmount = () => clearTimeout(this.state.timeout);
  close = () => {
    this.setState({ open: false, timeout: null });
  };
  open = () => {
    this.setState({
      open: true,
      timeout: setTimeout(this.close, 30000)
    });
  };
  render = () => (
    <section className="unveal">
      <div className="intro">
        {this.props.text}
        <ButtonBar>
          {this.state.open ? (
            <button onClick={this.close}>{this.props.t('unveal.close')}</button>
          ) : (
            <button onClick={this.open}>{this.props.t('unveal.open')}</button>
          )}
        </ButtonBar>
      </div>
      {this.state.open ? this.props.children : null}
    </section>
  );
}

export default withTranslation()(Unveal);
