import React from 'react';
import PropTypes from 'prop-types';
export default class Info extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    childrem: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  };
  render = () => (
    <section className="alert-box info">
      {this.props.heading && <h2>{this.props.heading}</h2>}
      <div className="message">{this.props.message ?? this.props.children}</div>
    </section>
  );
}
