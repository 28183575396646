import React from "react";
import EthereumContext from "../context/EthereumContext";
import lib from "../lib";
import Wallet from "../wallet";
import Error from "../components/Error";
import { mdiHelpCircle, mdiCog, mdiLogoutVariant } from "@mdi/js";
import Button from "../components/Button";
import About from "../About";
import Settings from "../Settings";
import Form from "../components/Form";
import Close from "../components/Close";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import i18next from "i18next";
import LayoutComponent from "../components/Layout";
import Context from "../components/Context";

export default class Layout extends React.Component {
  state = {
    logoutDisabled: true,
    logout: () => console.error("Logout not Initialized"),
    purge: () => console.error("Purge not Initialized"),
    about: false,
    settings: false,
    options: {
      customerType: 0,
      language: i18next.language,
      currency: "EUR",
      provider: "mainnet",
      IBAN: null,
      security: 13,
      ...("options" in localStorage ? JSON.parse(localStorage.options) : {}),
    },
    error: null,
  };

  componentDidMount = () => {
    //window.onbeforeunload = () => false;
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      if (!msg.match(/metamask/i) && !msg.match(/bity/i))
        this.setState({
          error: {
            heading: msg,
            message: (
              <>
                <dl>
                  <dt>Error</dt>
                  <dd>{msg}</dd>
                  <dd>
                    <pre>{JSON.stringify(error, null, 4)}</pre>
                  </dd>
                  <dt>File:Line:Column</dt>
                  <dd>{url + ":" + lineNo + ":" + columnNo}</dd>
                </dl>
              </>
            ),
          },
        });
      if (!this.state.options.security)
        this.setState({ security: i18next.language });
      if (!this.state.options.language)
        this.setState({ language: i18next.language });
      if (this.state.options.language !== i18next.language)
        i18next.changeLanguage(this.state.options.language);
      return false;
    };
    if ("provider" in localStorage) localStorage.removeItem("provider"); // cleanup from old releases
    this.setProvider();
  };

  componentWillUnmount = () => {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      console.error({ msg, url, lineNo, columnNo, error });
      return false;
    };
  };

  setProvider = async (
    provider = this.state.options.customerType < 20 ? "mainnet" : null
  ) => {
    if (!provider && "provider" in this.state.options)
      provider = this.state.options.provider;
    let libProvider = await lib.setProvider(provider);
    if (libProvider && "name" in libProvider)
      this.setOption("provider", libProvider.name);
  };

  setOption = async (key, value) => {
    if (key === "customerType" && value < 20) this.setProvider("mainnet");
    if (key === "language") await i18next.changeLanguage(value);
    localStorage.options = JSON.stringify({
      ...this.state.options,
      [key]: value,
    });
    this.setState((p) => ({ options: { ...p.options, [key]: value } }));
  };

  setLogoutDisabled = (disabled) => this.setState({ logoutDisabled: disabled });

  setLogoutCallback = (cb) => this.setState({ logout: cb });
  setPurgeCallback = (cb) => this.setState({ purge: cb });

  about = () => {
    localStorage.accepted = JSON.stringify(new Date());
    this.setState({ about: true });
  };

  render = () => (
    <>
      <I18nextProvider i18n={i18n}>
        <EthereumContext.Provider
          value={{
            provider: lib.provider(this.state.options.provider),
            setProvider: this.setProvider,
            about: this.about,
            options: this.state.options,
            setOption: this.setOption,
            setLogoutDisabled: this.setLogoutDisabled,
            setLogoutCallback: this.setLogoutCallback,
            setPurgeCallback: this.setPurgeCallback,
          }}
        >
          <Context.Provider value={{}}>
            <LayoutComponent
              nav={
                <>
                  <Button
                    onClick={() => this.setState({ settings: true })}
                    icon={mdiCog}
                  >
                    {this.state.options.provider !== "mainnet" ? (
                      <>
                        <span className='warn'>
                          {this.state.options.provider}
                        </span>{" "}
                      </>
                    ) : null}
                    {this.state.options.currency}
                  </Button>
                  <Button onClick={this.about} icon={mdiHelpCircle} />
                  <Button
                    disabled={this.state.logoutDisabled}
                    icon={mdiLogoutVariant}
                    onClick={this.state.logout}
                  />
                </>
              }
            >
              <Form show={this.state.about}>
                <Close
                  active={this.state.about}
                  close={() => this.setState({ about: false })}
                >
                  <About />
                </Close>
              </Form>
              <Form show={this.state.settings}>
                <Close
                  active={this.state.settings}
                  close={() => this.setState({ settings: false })}
                >
                  <Settings
                    nopurge={this.state.logoutDisabled}
                    purge={this.state.purge}
                  />
                </Close>
              </Form>
              {this.state.error ? <Error {...this.state.error} /> : <Wallet />}
            </LayoutComponent>
          </Context.Provider>
        </EthereumContext.Provider>
      </I18nextProvider>
    </>
  );
}
