import React from "react";
import { withTranslation } from "react-i18next";
import Grid from "./components/Grid";
import Container from "./components/Container";
import Card from "./components/Card";
import coinwallet from "./style/images/coinwallet.svg";
import PropTypes from "prop-types";

class StepsToCoin extends React.Component {
  static propTypes = {
    current: PropTypes.number,
  };
  render = () => (
    <>
      <div className='to-inverse'>
        <p>
          <img src={coinwallet} alt='' />
        </p>
      </div>
      <Container inverse>
        <h2>
          {this.props.current === 1
            ? this.props.t("stepstocoin.title")
            : this.props.t("stepstocoin.title2")}
          <span className='subtitle'>
            {this.props.current === 1
              ? this.props.t("stepstocoin.subtitle")
              : this.props.t("stepstocoin.subtitle2")}
          </span>
        </h2>
        {!this.props.current ||
          (this.props.current < 3 && (
            <Grid size='2'>
              <Card
                type={
                  this.props.current
                    ? this.props.current > 1
                      ? "done"
                      : "current"
                    : ""
                }
                icon={<p>1.</p>}
              >
                {this.props.t("stepstocoin.step1")}
              </Card>
              <Card
                type={
                  this.props.current
                    ? this.props.current > 2
                      ? "done"
                      : this.props.current === 2
                      ? "current"
                      : ""
                    : ""
                }
                icon={<p>2.</p>}
              >
                {this.props.t("stepstocoin.step2")}
              </Card>
            </Grid>
          ))}
        <Grid size='1'>
          <Card
            type='large'
            icon={
              <svg viewBox='0 0 24 24' width='24' height='24'>
                <path
                  fill='currentColor'
                  d='M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23M15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11Z'
                />
              </svg>
            }
          >
            {this.props.t("stepstocoin.done")}
          </Card>
        </Grid>
      </Container>
      <div className='from-inverse' />
    </>
  );
}
export default withTranslation()(StepsToCoin);
