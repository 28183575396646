import { ethers } from "ethers";

const libethers = () => {
  //const main = window.location.origin.replace(/(https?:\/\/[^:/]*)[:/].*$/, "$1") + ":8545";
  const main = null; //"https://pacta.cash:8545";
  if (typeof libethers.providers === "undefined")
    libethers.providers = [
      {
        name: "metamask",
        valid: false,
        provider: null, // new ethers.providers.Web3Provider(web3.currentProvider);
      },
      {
        name: "mainnet",
        valid: true,
        provider:
          main && main.match(/^https?:\/\//)
            ? new ethers.providers.JsonRpcProvider(main)
            : ethers.getDefaultProvider("homestead"),
        etherscan: new ethers.providers.EtherscanProvider("homestead"),
      },
      {
        name: "ropsten",
        valid: true,
        provider: ethers.getDefaultProvider("ropsten"),
        etherscan: new ethers.providers.EtherscanProvider("ropsten"),
      },
      {
        name: "kovan",
        valid: true,
        provider: ethers.getDefaultProvider("kovan"),
        etherscan: new ethers.providers.EtherscanProvider("kovan"),
      },
      {
        name: "rinkeby",
        valid: true,
        provider: ethers.getDefaultProvider("rinkeby"),
        etherscan: new ethers.providers.EtherscanProvider("rinkeby"),
      },
      {
        name: "görli",
        valid: true,
        provider: ethers.getDefaultProvider("goerli"),
        etherscan: new ethers.providers.EtherscanProvider("goerli"),
      },
    ];

  libethers.setProvider = async (provider = null) => {
    if (typeof provider === "string")
      provider = libethers.providers.find(
        (v) => v.valid && v.name === provider
      );
    if (provider === null) provider = libethers.providers.find((v) => v.valid);
    libethers.provider = provider;
    return provider;
  };

  return {
    providers: libethers.providers,
    provider: (name = null) =>
      name
        ? libethers.providers.find((v) => v.valid && v.name === name)
        : libethers.provider,
    setProvider: libethers.setProvider,
  };
};

export default libethers();
